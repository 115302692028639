<template>
  <v-container>
    <v-row>
      <div>
        <input v-model="email" placeholder="Enter email" />
        <b-input-group>
          <b-button style="width: auto" @click="getContact()"
            >Get Contact</b-button
          >
          <b-button
            class="offers-button"
            style="width: auto"
            @click="getContacts()"
            >Get All Contacts</b-button
          >
        </b-input-group>
      </div>
    </v-row>

    <p style="margin-top: 40px;">  space </p>
      <v-row >
        <div >
                <div class="popup-wrap">
                  <div class="pop-up-header">Engage GHL Seller Record</div>

                  <v-btn class="close-popup" text @click="closeGHL">
                    <v-img
                      src="../assets/close-icon.png"
                      alt="close-icon"
                    ></v-img>
                  </v-btn>

                  <v-row id="GHL Email" style="margin-top: 1px;">
                    <v-col cols="10">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text style="font-family: Lato, sans-serif;">Seller Email:</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="ghlEmail"
                          style="background-color: lightgoldenrodyellow; 
                          margin-right: 1px;
                          padding-right: 0px;"
                          
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="2">
                      <b-button  class="engage-button" 
                        @click="getGHLUser"
                      >
                        Get Contact
                      </b-button>
                    </v-col>
                  </v-row> 
                  
                  <v-row id="Company">  
                    <v-col cols="12">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Company:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.company"
                          style="background-color: white"
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>


                  </v-row>                 
                  <v-row id="First - Last Name/Telephone">
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">First Name:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.first_name"
                          style="background-color: white"

                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Last Name:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.last_name"
                          style="background-color: white"

                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Tel:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.phone"
                          style="background-color: white"

                        >city_id
                        </b-form-input>
                      </b-input-group>
                    </v-col>                  
                  </v-row>  
                  
                  <v-row id="City/State/Zip">
                    <v-col cols="5">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">City:</b-input-group-text>
                        </template>
                        <b-form-select class="input-standard"
                          v-model="ghlSellerItem.city_id"
                          style="background-color: white"
                          :options="list_city"
                          value-field="id"
                          text-field="text"                       
                        >
                        </b-form-select>
                      </b-input-group>
                    </v-col>
                    <v-col cols="3">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">State:</b-input-group-text>
                        </template>
                        <b-form-select class="input-standard"
                          v-model="ghlSellerItem.state_id"
                          style="background-color: white"
                          :options="list_states"
                          value-field="id"
                          text-field="text"                       
                        >
                        </b-form-select>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Zip:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.zip"
                          style="background-color: white"
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>

                  
                    <v-row id="UID/Tags">
                      <v-col cols="5">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text 
                            class="input-standard">UID:</b-input-group-text>
                          </template>
                          <b-form-input class="input-standard"
                            v-model="ghlSellerItem.uid"
                            @change="getDupUID(ghlSellerItem.uid)"
                            style="background-color: white; border-color: red; text-align: center;"
                            :rules="[
                                        (v) => !!v || 'This field is required',
                                      ]"                          
                          >
                          </b-form-input>
                        </b-input-group>
                      </v-col> 
                      <v-col cols="2"></v-col>  
                      <v-col cols="5">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text 
                            class="input-standard">Tags:</b-input-group-text>
                          </template>
                          <b-form-input class="input-standard"
                            v-model="tags"
                            style="background-color:azure; border-color: red; text-align: center;"
                          
                          >
                          </b-form-input>
                        </b-input-group>
                      </v-col>                                     
                  </v-row>                 

                    <v-row id="SAVE BUTTONS" style="margin-top:10px;">
                      <!-- <v-col cols="1"></v-col> -->
                      <v-col cols="6">
                        <b-button-group >
                          <button class="save-button-ghl" @click="saveGHLFormData">Save Seller</button>
                          <button class="engage-button-ghl" @click="engageGHLSeller">Save and Engage GHL Seller</button>
                        </b-button-group>
                      </v-col>

                    </v-row>
                  </v-row> 

                </div>
        </div>
      </v-row>  

  </v-container>
</template>

<script>
import axios from "axios";
import CommonServices from "../service/CommonServices";

export default {
  data() {
    return {
      email: null,
      contact: {},
      list_states: [],
      list_city: [],
      ghlEmail: "",
      tags: "",
      zipCodesList: [],

      ghlSellerItem: {
        id: "",
        company: "",
        first_name: "",
        last_name: "",
        city_id: "",
        state_id: "",
        zip: "",  
        phone: "",
        email: "",
        user_type_id: 3,
        Tag: "",
      },      
    };
  },

 
  created() {
        CommonServices.getList('ZipCodes').then((res) => {
            this.zipCodesList = res.results;
            console.log('ZipCodes List:',this.zipCodesList)
        }).catch((err) => {
            console.log(err);
        });

        this.getCities();
        this.getStates();

        }, 

  methods: {

    async getGHLUser(){
      console.log('Pulling GHL user info for',this.ghlEmail)
      try {
        const response = await axios.get(`/db/contact?email=${this.ghlEmail}`);
        if(Array.isArray(response.data)){
          console.log('Is Array')
          const [contact] = response.data;
          this.contact = contact;
          console.log('Contact:', this.contact.contacts)
          console.log ('Email',this.contact.contacts.email)
        } else {
          console.log('Is NOT Array')
          this.contact = response.data;
          console.log('Contact:', this.contact.contacts);
          console.log('First Name:', this.contact.contacts[0 ].firstName);
          this.ghlSellerItem.company=this.contact.contacts[0].company_name;
          this.ghlSellerItem.first_name=this.contact.contacts[0].firstName;
          this.ghlSellerItem.last_name=this.contact.contacts[0].lastName;
          this.ghlSellerItem.phone=this.contact.contacts[0].phone;
          this.ghlSellerItem.zip=this.contact.contacts[0].postalCode; 
          this.getCityState(this.ghlSellerItem.zip)
          this.tags=this.contact.contacts[0].tags;        
        }
      } catch (error) {
        console.error(error);
      }      
    },


    getCityState(zip) {
      const filterZipData = this.zipCodesList.find(item => item.zipCode == zip);
      if(!filterZipData){
          console.log('No ZipCode found for', zip)
          return false
      } else{
        console.log('Found CityID:',filterZipData.city_id)
        this.ghlSellerItem.city_id = filterZipData.city_id;
        this.ghlSellerItem.state_id = filterZipData.state_id;
      }               

  },


  getCities() {
      CommonServices.getList("Cities")
        .then((res) => {
          console.log("Cities", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_city.push({ id: code.id, text: code.city })
          );
          console.log('List_City',this.list_city)
        })
        .catch((err) => {
          console.log(err);
        });
    },


    getStates() {
      CommonServices.getList("States")
        .then((res) => {
          console.log("States", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_states.push({ id: code.id, text: code.state })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeGHL() {
      return
    },

    engageGHLSeller(){
      return
    },
    
    saveGHLFormData(){
      return
    },

    async getContact() {
      try {
        const response = await axios.get(`/db/contact?email=${this.email}`);
        this.contact = response.data;
        console.log('Contact:', this.contact)
        this.ghlSellerItem.company=this.contact.contacts[0].company_name;
        this.ghlSellerItem.first_name=this.contact.contacts[0].firstName;
        this.ghlSellerItem.last_name=this.contact.contacts[0].lastName;
        this.ghlSellerItem.phone=this.contact.contacts[0].phone;
        this.ghlSellerItem.zip=this.contact.contacts[0].postalCode;  
        this.ghlSellerItem.Tag=this.contact.contacts[0].tags;       
      } catch (error) {
        console.error(error);
      }
    },
    async getContacts() {
      try {
        const response = await axios.get("/db/contacts");
        this.contact = response.data;
        console.log('Contacts:', this.contact)
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
